.background /* He calls it .container */
{
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    /* min-height: 100vh;
    width: 100%; */
    /* background: #0c192c; */
    background: #23242a;
    /* background: url('../images/forest.jpg') no-repeat center/cover; */
    z-index: 0;
}



.background__container {
    position: relative;
    width: 100%;
    height: 100vh;
    /* background: #23242a; */
    /* overflow: hidden; */
}

.background__bubbles {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 0px;
    width: 100%;
    z-index: -100;
}

.background__bubbles span {
    position: relative;
    width: 4px;
    height: 4px;
    background: #4fc3dc;
    margin: 0 4px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #4fc3dc44,
        0 0 50px 7px #4fc3dc,
        0 0 100px 10px #4fc3dc;
    -webkit-transform: translate3d(0, 0, 0);
    animation: animate 15s linear infinite;
    animation-duration: calc(600s / var(--i));
}
.background__bubbles span:nth-child(even) {
    background: #20ea20;
    box-shadow: 0 0 0 4px #2dff2d44,
        0 0 50px 7px #3bb23b,
        0 0 100px 10px #3bb23b;
}

@keyframes animate
{
    0%
    {
        transform: translateY(98vh) scale(0);
        opacity: 1;
    }
    96%
    {
        transform: translateY(52px) scale(1);
        opacity: 1;
    }
    100%
    {
        transform: translateY(2px) scale(0);
        opacity: 0;
    }
}
