.about {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.about__container {
    flex-grow: 1;
    z-index: 10;
    padding: 100px 0 30px;
}
.about__container h3 {
    margin-left: 16%;
    font-size: var(--font-size-heading);
    color: var(--heading-text);
}
.about__paragraph {
    margin: 70px 16% 50px;
    font-size: var(--font-size-body);
    font-weight: 300;
    line-height: 28px;
    color: var(--body-text);
    border-radius: var(--border-radius-large);
}
.about__paragraph span {
    color: var(--color-green-500);
}
.about__paragraph span:nth-child(2) {
    color: var(--color-aqua-500);
    white-space: nowrap;
}
.about__paragraph span:nth-child(3) {
    color: var(--color-purple-500);
}


.about__tech-stack {
    /* width: calc(100%-20px); */
    font-size: var(--font-size-subheading);
    text-align: center;
    color: var(--content-fade-text);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 90px;
}
.about__tech-stack p {
    font-size: var(--font-size-small);
    margin: 0;
}
.about__tech-stack svg {
    display: block;
    margin: auto;
    color: var(--icon-text);
}

.tech-stack__current,
.tech-stack__past {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem 1rem;
    padding: 4rem 1.5rem 1.5rem;
    border-radius: 0 20px 20px 0;
    box-shadow: var(--clear);
    background: var(--dark-see-through);
    color: var(--body-text);
}
.tech-stack__current h4,
.tech-stack__past h4 {
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-weight: 600;

}


.tech-stack__past {
    border-radius: 20px 0 0 20px;
    /* border: 1px solid aqua; */
    background-image: var(--content-background-secondary);
}

.about__paragraph {
    padding: 20px;
    background: var(--content-background-primary);
    box-shadow: var(--clear);
}

.about__education {
    margin-top: 50px;
}

.about__education h3 {
    margin-left: 38px;
    font-size: 2rem;
    border-bottom: 2px solid var(--color-neutral-100);
}

.education__container {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    /* border: 2px solid red; */
}

.education__school {
    display: flex;
    align-items: center;
    /* border: 2px solid red; */

    background: var(--content-background-primary);
    padding: 5px 12px;
    box-shadow: var(--clear);
    border-radius: var(--border-radius-small);
    min-height: 150px;
}

.education__school img {
    height: 100px;
    width: 100px;
    border-radius: var(--border-radius-medium);
    margin: 8px 30px 8px 10px;
    object-fit: cover;
}
.education__school div {

    height: max-content;
}
.education__school h4 {
    font-size: var(--font-size-button-text);
    color: var(--heading-text);

}

.education__school p {
    font-size: 1.1rem;
    font-weight: 300;
    margin: 4px 0;
    color: var(--body-text);
}

@media screen and (max-width: 960px) {

    .about__tech-stack {
        display: block;
        height: 800px;
        position: relative;
    }

    .tech-stack__current {
        width: 90%;
        /* border: 1px solid red; */
        /* padding: 4.5rem .2rem .2rem; */
    }

    .tech-stack__past {
        position: absolute;
        bottom: 20px;
        right: 0;
        width: 90%;
        /* border: 1px solid blue; */

    }

    .about__paragraph {
        width: 80%;
        /* border: 2px solid red; */
        margin-left: 10%;
    }

    .education__container {
        display: block;
    }

    .education__school {
        margin-top: 20px;
    }

    .education__school h4 {
        font-size: 1.5rem;
    }

    .education__school p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 560px) {
    .about__tech-stack {
        height: 710px;
    }
    .about__tech-stack p {
        font-size: .7rem;
    }

    .about__container h3 {
        margin-left: 2%;
    }

    .tech-stack__current,
    .tech-stack__past {
        gap: 1.4rem 1rem;
    }

    .about__paragraph {
        width: 92%;
        /* border: 2px solid red; */
        margin-left: 4%;
    }

    .education__container {
        padding: 4px;
    }
    .education__school img {
        margin-right: 10px;
    }
    .education__school h4 {
        font-size: 1.2rem;
    }
}