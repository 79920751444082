.home2 {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    place-items: center;
}

.home__container2 {
    flex-grow: 1;
    position: relative;
    z-index: 1;
}
.home__element2 {
    margin: 100px auto 180px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: min(800px, 90%);
    height: 400px;
    border-radius: var(--border-radius-medium);
    overflow: hidden;
    box-shadow: var(--clear);
}
.home__text2 {
    padding: 20px;
    background: rgba(151, 218, 212, 0.627);
    background: var(--content-background-primary);
    color: var(--body-text);
    backdrop-filter: blur(3px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}
.home__container2 h1 {
    font-size: var(--font-size-display);
}
.home__text2 h4 {
    font-size: var(--font-size-heading);
    font-weight: 500;
}
.home__text2 p {
    font-weight: 200;
    display: block;
    font-size: var(--font-size-body);
}
.home__text2 span {
    /* color: var(--secondary); */
    white-space: nowrap;
}
.home__text2 span:nth-child(2) {
    /* color: var(--primary-500); */
    white-space: nowrap;
}

.home__text2 button {
    color: var(--button-text);
    border: 2px solid var(--button-border);
    font-size: var(--font-size-button-text);
    font-weight: 600;
    padding: 18px 25px;
    border-radius: var(--border-radius-small);
    white-space: nowrap;
    background-color: var(--button-background);
    transition: .5s;

}
.home__text2 button:hover {
    cursor: pointer;
    border: 2px solid var(--button-hover-border);
    background: var(--button-hover-background);
    color: var(--button-hover-text);
}

.home__container2 .headshot2 {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: .9;
}


@media screen and (max-width: 850px) {
    .home__element2 {
        grid-template-columns: 1fr;
        height: 700px;
    }
}