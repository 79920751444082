.project__container {
    min-height: 100vh;
    width: 100%;
    /* background-color: #23232a; */
    /* color: var(--color-neutral-100); */
    display: flex;
    flex-direction: column;
    /* border: 1px solid white; */
}

.project__page {
    flex-grow: 1;
    /* border: 1px solid lime; */
    padding: 50px 0 0;
    width: min(900px, 95vw);
    margin: auto;
    z-index: 10;
}




.more__content {
    margin-top: 30px;
    color: var(--color-green-300);
    border: 1px solid var(--color-green-800);
    padding: 4px 10px;
    border-radius: 4px;
}
.more__content:hover {
    filter: brightness(1.3);
    cursor: pointer;
}


.project__grid {
    display: grid;
    grid-template-columns: 60% 40%;
    /* border: 2px solid yellow; */
    padding: 30px 0;
}


.grid__left {
    /* border: 2px solid red; */
    width: 95%;
}

.grid__tech-title {
    margin-top: 50px;
    border-bottom: 1px solid var(--color-neutral-100);
}

.grid__right {
    position: relative;
    /* border: 2px solid aqua; */
    /* width: 50%; */
}

.grid__right img {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 95%;
    height: clamp(250px, 35vw, 370px);
    object-fit: cover;
    border-radius: var(--border-radius-medium);
    box-shadow: var(--clear);

    /* border: 2px solid lime; */
    /* margin-right: 10px; */
}

.cta__container {
    /* border: 1px solid aqua; */
    width: max(340px, 100%);
    /* height: max-content; */
    padding: 20px;
    background: rgba(8, 49, 64, 0.838);
    background: var(--dark-see-through);
    box-shadow: var(--clear);
}

.cta__title {
    width: 90%;
    color: var(--heading-text);
    position: static;
    text-align: left;
    padding-bottom: 10px;
}
.cta__title h1 {
    border-bottom: 1px solid var(--color-neutral-100);
    font-size: var(--font-size-heading);
}
.cta__title p {
    padding: 5px 0 10px;
    color: var(--body-text);
}

.cta__credentials {
    display: block;
    margin: 10px 0 30px;
}
.cta__credentials p {
    padding: 4px 0;
    font-size: 1.2rem;
}
.cta__credentials p:nth-child(2) {
    font-size: 1.05rem;
    margin-bottom: 30px;
}
.cta__credentials h4 {
    color: var(--color-green-500);
    font-size: 1.3rem;
    margin-bottom: 10px;
    margin-right: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}
.cta__credentials h4 span {
    color: var(--color-neutral-100);
    font-weight: 300;
    display: block;
    letter-spacing: 1.5px;
}

.cta__links {
    display: flex;
    justify-content: space-between;
    width: min(100%, 450px);
}
.cta__links p {
    color: var(--body-text);
}
.cta__links button {
    font-size: var(--font-size-button-text);
    font-weight: 600;
    padding: 18px 25px;
    border-radius: var(--border-radius-small);
    white-space: nowrap;
    color: var(--button-text);
    border: 2px solid var(--button-border);
    background-color: var(--button-background);
    transition: .5s;

}
.cta__links button:hover {
    cursor: pointer;
    border: 2px solid var(--button-hover-border);
    background: var(--button-hover-background);
    color: var(--button-hover-text);
}


.grid__tech-title {
    color: var(--heading-text);
    font-size: var(--font-size-heading);
}
.tech-stack__container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 3%;
    
    /* gap: 4%; */
    /* border: 2px solid blue; */
}

.tech-stack__frontend,
.tech-stack__backend,
.tech-stack__utilities {
    width: 160px;
    padding: 12px 22px;
    height: 210px;
    border-radius: 20px;
    background: linear-gradient(140deg, rgba(236, 247, 167, 0.769), rgba(22, 180, 14, 0.76));
    position: relative;
    color: #222;
    margin-top: 10px;
    box-shadow: var(--clear);
    /* border: 2px solid yellow; */
}
.tech-stack__frontend ul,
.tech-stack__backend ul,
.tech-stack__utilities ul {
    list-style-type: none;
}
.tech-stack__frontend li,
.tech-stack__backend li,
.tech-stack__utilities li {
    padding-bottom: 3px;
    height: 30px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    /* border: 1px solid lime; */
}
.tech-stack__frontend svg,
.tech-stack__backend svg,
.tech-stack__utilities svg {
    font-size: 1.3rem;
    /* transform: translateY(1px); */
    margin-right: 8px;
    color: var(--color-neutral-900);
    display: block;
}
.tech-stack__frontend h3,
.tech-stack__backend h3,
.tech-stack__utilities h3 {
    text-align: center;
    position: absolute;
    font-size: 1.3rem;
    font-weight: 800;
    bottom: 6px;
    left: 50%;
    color: #1b1b1b;
    transform: translateX(-50%);
}

.tech-stack__backend {
    background: linear-gradient(140deg, rgba(111, 247, 199, 0.769), rgba(26, 186, 197, 0.769));
}
.tech-stack__utilities {
    background: linear-gradient(140deg, rgba(249, 146, 244, 0.769), rgba(248, 69, 69, 0.769));
}


@media screen and (max-width: 850px) {
    .cta__links button  {
        font-size: 1rem;
    }
}

@media screen and (max-width: 666px) {
    .project__page {
        padding: 70px 0 0;
    }

    .project__grid {
        grid-template-columns: 100%;
    }

    .grid__right {
        order: 1;
        margin: 0 auto 40px ;
        width: 70%;
        /* height: 50vw; */
    }

    .grid__left {
        order: 2;
        width: 100%;
    }

    .grid__right img {
        position: static;
        margin: auto;
        height: min(200px, 50vw);
    }

    .cta__container {
        margin: auto;
        width: 98%;
    }
    .cta__credentials h4 {
        font-size: 1rem;
    }
    .cta__credentials h4 span {
        display: block;
    }

    .tech-stack__container {
        margin: 20px auto;
        /* border: 2px solid yellow; */
        /* justify-content: space-around; */

    }

}

@media screen and (max-width: 360px) {
    .cta__links button {
        padding: 5px 8px;
    }
}



/* -------- For more content ---------- */
.projectProblem {
    /* border: 1px solid orange; */
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}
.projectProblem img {
    width: 45%;
    object-fit: cover;
}
.projectProblem p {
    width: 90%;
}

.projectProject {
    /* border: 1px solid yellow; */
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}
.projectProject div {
    /* border: 2px solid blue; */
    padding-left: 10%;
}
.projectProject img {
    width: 45%;
    object-fit: cover;
}
.projectProject p {
    /* border: 1px solid lime; */
}



.projectSolutions {
    /* border: 1px solid green; */
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    /* border: 2px solid blue; */
}
.projectSolutions div {
    /* border: 2px solid lime; */
    width: 50%;
}
.projectSolutions a img {
    width: 100%;
    object-fit: cover;
}

.projectSolutions a {
    width: 45%;
    /* border: 2px solid yellow; */
}

.projectSolutions p {
    width: 90%;
}







.projectTechnologies {
    border: 1px solid violet;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    height: 370px;
}
.projectTechnologies img {
    height: 100%;
    width: 45%;
    object-fit: cover;
    filter: brightness(.9);
    cursor: pointer;
    border: 1px solid yellow;
}






.techTextContent {
    width: 45%;
    border: 2px solid blue;
}











.projectFuture {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}
.projectFuture div {
    /* border: 2px solid blue; */
}
.projectFuture img {
    width: 45%;
    object-fit: cover;
}
.projectFuture p {
    /* border: 1px solid lime; */
    width: 90%;
}



/* .textContent {
    display: flex;
    columns: 2;
    column-gap: 40px;
    padding: 5px 20px;
    height: max-content;
}

.textContent p {
    width: 45vw;
    font-weight: 200;
    margin-bottom: 20px;
    padding: 5px 0;
} */



.technologies {
    background-color: #3e29293f;
    border-radius: 20px;
    padding: 8px;
    border: 1px solid red;
}