.toggle {
  background: var(--green-blue-fade);
  padding: 12px 20px;
  position: absolute;
  bottom: 30px;
  right: 25px;
  border-radius: var(--border-radius-large);
  max-width: 220px;
  z-index: 10000;
  box-shadow: var(--clear);
}
.background-button {
    /* position: absolute;
    top: 40px;
    left: 5px; */
    padding: 5px;
    z-index: 100;
    border-radius: var(--border-radius-small);
    border: 2px solid var(--button-border);
    color: var(--button-text);
    background-color: var(--button-background);
  }

  .background-button:hover {
    background: var(--button-hover-background);
    color: var(--button-hover-text);
    border: 2px solid var(--button-hover-border);
    cursor: pointer;
  }
  .background-title {
    /* position: absolute;
    top: 80px;
    left: 5px; */
    color: #333;
    text-align: center;
    margin-top: 8px;
    /* background: var(--dark-see-through);
    padding: 5px 15px;
    border-radius: var(--border-radius-medium); */
  }

  .background-picker-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin-top: 10px;
  }

  .background-picker-grid-item {
    width: 50px;
    height: 50px;
    border: 4px solid white;
    border-radius: var(--border-radius-medium);
    box-shadow: var(--clear);
  }

  .background-picker-grid-item:hover {
    cursor: pointer;
    border: 4px solid var(--color-green-500);
  }