.clear {
    background: var(--color-neutral-900);
    min-height: 100vh;
    width: 100%;
    color: var(--content-fade-text);
    display: flex;
    flex-direction: column;
    place-items: center;
}

.clear-container {
    flex-grow: 1;
    position: relative;
    z-index: 1;
    margin-bottom: 140px;
}