
.navbarHeader {
    background: linear-gradient(#1414198d, transparent) ;
    background: var(--content-background-primary);
    backdrop-filter: blur(3px);
    /* width: calc(100%-20px); */
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: var(--nav-link);
    padding: 0.25rem 0.5rem;
    z-index: 100;
    position: absolute;
    height: 35px;
    border-bottom: 1px solid var(--color-neutral-400);
}

.navLogo {
    display: flex;
    flex-direction: row;
    align-self: center;
}
.navLogo h2 {
    font-size: 20px;
    font-weight: 700;
    color: var(--nav-link);
    margin-top: 14px;
    width: 120px;
}


.navMenuContainer {
    display: flex;
    align-items: center;
}
.navMenuContainer ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    z-index: 50000;
    /* color: var(--white); */
}

.navLink {
    list-style-type: none;
}

.navLink a {
    font-size: 1.2rem;
    text-decoration: none;
    cursor: pointer;
    /* color: var(--white); */
}
.navLink a:visited {
    color: var(--nav-link);
}
.navLink a:hover {
    color: var(--nav-link-hover);
}

.active-link {
    text-decoration: underline;
    text-decoration-color: var(--nav-link);
}

.navbarHeader .icon {
    display: none;
}

@media screen and (max-width: 665px) {
    .navbarHeader {
        height: 50px;
    }
    .navbarHeader .icon {
        background-color: transparent;
        border: none;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0px;
        right: 3px;
        z-index: 1000;
    }
    .hamburgerIcon,
    .hamburgerIcon::before,
    .hamburgerIcon::after {
        background-color: var(--nav-link);
        width: 40px;
        height: 5px;
        border-radius: 5px;
        position: absolute;
        transition: all 1s;
    }
    .hamburgerIcon::before,
    .hamburgerIcon::after {
        content: '';
    }
    .hamburgerIcon::before {
        transform: translate(-20px, -12px)
    }
    .hamburgerIcon::after {
        transform: translate(-20px, 12px)
    }

    .eActive {
        background-color: transparent;
        transform: rotate(1440deg);
    }
    .aActive .hamburgerIcon::before {
        transform: translateX(-20px) rotate(45deg);
    }
    .aActive .hamburgerIcon::after {
        transform: translateX(-20px) rotate(-45deg);
    }

    .hamburgerIcon:hover,
    .hamburgerIcon:hover::before,
    .hamburgerIcon:hover::after {
        background-color: var(--nav-link-hover);
    }
    .aActive .hamburgerIcon:hover {
        background-color: transparent;
    }
    .navbarHeader .icon:hover {
        cursor: pointer;
    }


    .navMenuContainer {
        display: grid;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: -100%;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100vh;

        background: #4a207ed7;
        background: linear-gradient(40deg, rgb(108, 0, 162), rgb(0, 17, 82));
        /* backdrop-filter: blur(50px); */
        z-index: 100;
        transition: left 1s;
        
        /* border: 2px solid lime; */
    }

    .active {
        left: 0px;
        
    }

    .navMenuContainer ul {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        list-style-type: none;
    }

    .navLink {
        font-size: 1.7rem;
        width: max-content;
        text-decoration: none;
    }
    .navLink a {
        font-size: 2rem;
        font-weight: 500;
    }
}

@media screen and (max-width: 350px) {
    .navbarHeader h1 {
        font-size: 1rem;
    }
}


