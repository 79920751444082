.projects {
    min-height: 100vh;
    width: 100%;
    margin: 0rem;
    position: relative;
    z-index: 10;
}

.projects__container {
    flex-grow: 1;
    padding: 80px 10px;
    display: flex;
    flex-direction: column;
}


.projects__helper {
    color: var(--body-text);
    padding: 16px 13px;
    display: inline-block;
    margin: 0 auto;
    border-radius: var(--border-radius-small);
    background: var(--content-background-secondary);
    box-shadow: var(--clear);
}
.projects__helper p {
    margin-bottom: 12px;
    font-size: var(--font-size-subheading);
    white-space: nowrap;
    display: inline;
}






.projects__grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
    margin: 60px auto;
}

.projects__card {
    height: 400px;
    width: 300px;
    border-radius: var(--border-radius-large);
    position: relative;
    margin-bottom: 30px;
}

.projects__card img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: relative;
    object-position: center;
    transition: filter .3s;
    border: 3px solid rgba(97, 97, 97, 0.402);
    border-radius: var(--border-radius-large);
    box-shadow: var(--clear);
}

.projects__title {
    position: absolute;
    top: -40px;
    left: 0px;
    color: var(--heading-text);
    width: 100%;
    text-align: center;
    font-size: var(--font-size-body);
}







/* only for debugging */
.stateVariables {
    color: var(--color-neutral-100);
    position: absolute;
    top: 5px;
    left: 5px;
}

@media screen and (max-width: 500px) {
    .projects__container {
        padding: 20px 0;
    }
    .projects__helper {
        margin: 60px auto;
        width: 90%;
        
    }
    .projects__helper p {
        white-space: normal;
    }
    .projects__card {
        width: 80%;
        margin: 30px auto;
    }
}

@media (hover) {
    .projects__title {
        /* color: #f8f8f8; */
        color: transparent;
 
        position: absolute;
        height: 400px;
        width: 300px;
        border-radius: var(--border-radius-large);
        overflow: hidden;
        font-weight: 500;
        top: 50%;
        transform: translate(-2px, -50%);
        font-size: var(--font-size-button-text);
        transition: color .3s;
        /* border: 2px solid rgb(167, 251, 167); */
        padding: 10vmin 2vmin;
        text-align: center;
    }

    .projects__card:hover :not(.projects__title) {
        filter: opacity(.25);  
        
    }
    
    .projects__card:hover .projects__title {
        color: var(--color-green-500);
        border: 2px solid var(--color-green-300);
        backdrop-filter: blur(15px);
    }
}
