.footer {
    height: max-content;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: var(--content-background-primary);
    z-index: 100;
    border-top: 1px solid var(--color-neutral-400);
}

.footer__links {
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
}

.links__p {
    margin: 4px 20px;
    padding: 0;
}

.footer__links a {
    text-decoration: none;
    color: var(--nav-link);
}
.footer__links a:visited {
    color: var(--nav-link);
}
.footer__links p:hover {
    color: var(--nav-link-hover);
    cursor: pointer;
}

.footer__year {
    padding: 4px 10px;
    color: var(--body-text);
    white-space: nowrap;
}

@media screen and (max-width: 380px) {
    .footer {
        font-size: 11px;
    }
    .links__p {
        margin: 4px 10px;
    }
}