.contact {
    min-height: 100vh;
    /* background: #23242a; */
    display: flex;
    flex-direction: column;
    position: relative;
}
.contact__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    /* min-height: 100vh; */
    /* background: #23242a; */
    z-index: 10;
}

/* The actual box */
.contact__box {
    position: relative;
    width: 380px;
    height: 440px;
    border-radius: 8px;
    overflow: hidden;
    background: #11111174;
    box-shadow: 6px 6px 6px 0px rgba(0, 0, 0, 0.379);
}


.box__border {
    position: absolute;
    top: 0;
    inset: 0;
}


.contact__box form 
{
    position: absolute;
    inset: 4px;
    background: #2222221a;
    padding: 20px 20px;
    border-radius: 8px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(3px);
}

.contact__box form h2 
{
    color: #eee;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.1em;
}

.contact__box form .box__input 
{
    position: relative;
    width: 100%;
    margin-top: 20px;
}

.contact__box form .box__input input 
{
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    color: #23242a;
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    z-index: 10;
}

.contact__box form .box__input span
{
    position: absolute;
    left: 0;
    padding: 20px 0px 10px;
    pointer-events: none;
    color: #8f8f8f;
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
}

.contact__box form .box__input input:valid ~ span,
.contact__box form .box__input input:focus ~ span
{
    color: #eee;
    font-size: 0.75em;
    transform: translateY(-34px);
}

.contact__box form .box__input i
{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #eee;
    border-radius: 4px;
    overflow: hidden;
    transition: 0.5s;
    pointer-events: none;
}

.contact__box form .box__input input:valid ~ i,
.contact__box form .box__input input:focus ~ i
{
    height: 44px;
}


.contact__box form input[type="submit"]
{
    border: none;
    /* outline: none; */
    padding: 3px 25px;
    background: var(--button-background);
    cursor: pointer;
    font-size: var(--font-size-button-text);
    border: 2px solid var(--button-border);
    color: var(--button-text);
    border-radius: 4px;
    font-weight: 600;
    letter-spacing: .05rem;
    width: 130px;
    margin-top: 10px;
    transition: all .5s;
}

.contact__box form input[type="submit"]:active,
.contact__box form input[type="submit"]:hover
{
    opacity: 1;
    cursor: pointer;
    border: 2px solid var(--button-hover-border);
    background: var(--button-hover-background);
    color: var(--button-hover-text);
}


.contact__box form .box__input textarea
{
    position: relative;
    width: 100%;
    height: 150px;
    padding: 20px 10px 10px;
    background: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    color: #23242a;
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    z-index: 10;
    resize: none;
}



.contact__box form .box__input textarea:valid ~ span,
.contact__box form .box__input textarea:focus ~ span
{
    color: #eee;
    font-size: 0.75em;
    transform: translateY(-34px);
}


.contact__box form .box__input textarea:valid ~ i,
.contact__box form .box__input textarea:focus ~ i
{
    height: 150px;
}